:root {
  --dark-color: #262525;
  --darker-color: #1B1918;
  --light-color: #E3E1D7;
  --lighter-color: #FEFEFE;
  --main-color: #8A9247;
  --main-darker: #7e863f;
  --mid-color-lighter: #f2f1eb;
  --mid-color-light: #6F6D6C;
  --mid-color-mid: #3d3b3a;
  --mid-color-dark: #2a2929;
  --warning: #C14762;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fonts {
  font-family: 'EB Garamond', serif;
  font-family: 'Lato', sans-serif;
  font-family: 'PT Sans Caption', sans-serif;
  font-family: 'Fanwood Text', serif;
  font-family: 'Lexend Deca', sans-serif;
}

body {
  background-color: var(--lighter-color);
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  color: var(--dark-color);
  font-size: 16px;
  overflow-x: hidden;
  width: 100vw;
  min-height: 100vh;
}

a, a:hover, button, button:hover, li, li:hover,
img, img:hover, span, span:hover,
section div, section div:hover, i, i:hover,
h1, h1:hover, h2, h2:hover, h3, h3:hover,
h4, h4:hover, h5, h5:hover, h6, h6:hover {
  transition: all 0.5s ease-in-out;
}

a, button {
  color: var(--main-color);
  text-decoration: none;
  background: transparent;
  border: none;
  font-size: 1em;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}
a:hover, button:hover {
  color: var(--mid-color-mid);
  cursor: pointer;
}
ul {
  list-style-type: none;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

img {
  image-rendering: crisp-edges;
}

.center {
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  float: right;
  width: calc(90% - 300px);
}
.content {
  flex: 1;
  width: 1220px; max-width: 100%;
}

header {
  background-color: var(--darker-color);
  background-image: url(./assets/images/header.png);
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  color: var(--light-color);
  padding: 10px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(300px + 10%);
  height: 100vh;
  transition: all 0.5s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
}

h1  {
  font-family: 'Lexend Deca', sans-serif;
  text-transform: uppercase;
  font-size: 4.5em;
  text-align: center;
  line-height: 1;
  font-weight: normal;
}
h1 a, h1 a:hover {
  color: var(--light-color);
  font-family: 'Lexend Deca', sans-serif;
}
h2 {
  font-family: 'Fanwood Text', serif;
  font-size: 1.8em;
  text-align: center;
  line-height: 1;
  margin-top: 10px;
  font-weight: normal;
  font-style: italic;
}

.title {
  z-index: 1;
  position: relative;
  text-shadow: 1px 1px rgba(0,0,0,.3);
}
nav {
  order: 1;
  font-family: 'Lexend Deca', sans-serif;
  text-transform: uppercase;
  margin: 20px;
  font-size: 1.2em;
  font-weight: normal;
  position: relative;
  text-shadow: 1px 1px rgba(0,0,0,.3);
}
nav a {
  font-family: 'Lexend Deca', sans-serif;
}
nav ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}
nav li.main-nav {
  position: relative;
  margin: 5px 10px;
}

nav li .sub-menu {
  display: block;
  visibility: hidden;
  background-color: var(--main-color);
  color: var(--lighter-color);
  text-align: center;
  padding: 0;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 120%;
  transform: translateY(-50%);
  opacity: 0;
  text-shadow: 1px 1px rgba(0,0,0,.1);
  transition: all 0.1s ease-in-out;
  white-space: nowrap;
}

nav li .sub-menu::after {
  content: "";
  position: absolute;
  bottom: calc(50% - 5px);
  left: -5px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent var(--main-color) transparent transparent;
}

nav li:hover .sub-menu {
  visibility: visible;
  opacity: 1;
  z-index: 15;
}

nav .sub-menu li {
  padding: 4px;
  border-bottom: 1px solid var(--main-darker);
}
nav .sub-menu a {
  color: var(--lighter-color);
  font-size: .9em;
  letter-spacing: 0;
  margin: 0;
  padding: 8px;
  opacity: .9;
}
nav .sub-menu a:hover {
  opacity: 1;
  text-shadow: 1px 1px rgba(0,0,0,.2);
}
nav .sub-menu i { color: var(--lighter-color); }

nav li a.active {
  pointer-events: none;
}
nav a { color: var(--main-color); }
nav a:hover { color: var(--mid-color-lighter); }
nav .nav-title {
  font-family: 'Fanwood Text', serif;
  color: var(--light-color);
  text-transform: none;
  padding: 10px 20px;
  font-size: 1.4em;
  display: none;
}
nav .icon {
  font-size: 1.5em;
  line-height: 1;
  color: var(--light-color);
  display: none;
  transition: all 0.5s ease-in-out;
  background-color: var(--darker-color);
  padding: 13px 15px 15px 16px;
  width: 58px; height: 58px;
  border-radius: 0 0 0 10px;
}
nav .icon:hover {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.index-header {
  height: 100vh;
  width: 100%;
  padding: 0;
}
.index-header .title {
  order: 2;
}
.index-header nav ul {
  flex-direction: row;
}


/* SECTIONS */
section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  margin: 0;
  padding: 40px 40px 20px 40px;
}
section h3, .modal h3 {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 2em;
  font-weight: bold;
  letter-spacing: -1px;
  width: 100%;
  margin-bottom: 10px;
}
section h4, .modal h4 {
  font-family: 'Lexend Deca', sans-serif;
  text-transform: uppercase;
  font-size: 1.4em;
  letter-spacing: -1px;
  margin: 35px 0 -5px;
}
section div {
  text-align: left;
  width: 100%;
}
section p, .modal p, .modal ul {
  margin: 15px 0;
  width: 100%;
}
.button {
  background-color: var(--main-color);
  padding: 12px;
  color: var(--lighter-color);
  font-family: 'Lexend Deca', sans-serif;
  text-transform: uppercase;
}
.button:hover {
  background-color: var(--main-darker);
  color: var(--mid-color-lighter);
}


/* ABOUT */
.quick-info {
  display: flex;
  justify-content: center;
  margin: 0 0 20px;
}
.info-box {
  background-color: var(--main-color);
  border-radius: 50%;
  width: 160px;
  height: 160px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lighter-color);
}
.info-box .info-inner {
  /* transform: rotate(45deg); */
  text-align: center;
  padding: 10px;
  /* font-size: .9em; */
  line-height: 1;
}
.info-box .info-title {
  margin-bottom: 5px;
  font-family: 'Fanwood Text', serif;
  font-style: italic;
  font-size: 1.35em;
  text-align: center;
}
.info-box p {
  font-family: 'Lexend Deca', sans-serif;
  text-transform: uppercase;
  font-size: .85em;
  margin: 0;
}
.info-box:hover {
  animation: pulse 2s infinite linear;
}
@keyframes pulse {
  0% { transform: scale(1); }
  10% { transform: scale(1.05); }
  20% { transform: scale(1); }
  30% { transform: scale(1.05); }
  40% { transform: scale(1); }
}

.quick-skills {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
  margin: 15px 10px 10px;
}
.quick-skills li {
  background-color: var(--main-color);
  color: var(--lighter-color);
  font-family: 'Lexend Deca', sans-serif;
  flex: 1;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 0;
  font-weight: normal;
}
.quick-skills li:nth-child(2n) {
  background-color: var(--mid-color-mid);
}
.quick-skills li:hover {
  border-radius: 0 20px;
}

.brand {
  font-family: 'Fanwood Text', serif;
  background-color: rgba(241,242,235,.2);
  border: 1px solid var(--mid-color-lighter);
  padding: 20px;
  width: calc(100% - 40px);
  margin: 15px auto 25px;
  color: var(--mid-color-mid);
  font-style: italic;
  font-size: 1.2em;
  line-height: 1.1;
  border-radius: 0 25px 0 25px;
}

.code-logos {
  color: var(--mid-color-mid);
  font-size: 2.2em;
  text-align: center;
}
.code-logos i {
  margin: 8px 5px;
}
.code-logos i:hover {
  opacity: .9;
}

.self-image img {
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  width: calc(5% + 150px);
  border: 5px solid var(--mid-color-lighter);
  float: left;
  margin: 0 25px 15px 0;
  shape-outside: circle(50%);
}

.button-resume {
  margin: 15px 0 0 0;
  display: inline-block;
}


/* CONTACT */
#contact p {
  text-align: left;
  width: 100%;
  padding: 10px 15px;
  margin: 0;
}
#contact h4 {
  width: 100%;
  text-transform: capitalize;
  font-size: 1.5em;
  margin: 30px 15px 0;
}
form.contact-form {
  width: 700px; max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
form.contact-form label {
  display: block;
  margin: 15px 0 5px;
}
form.contact-form .required { color: var(--warning); }
form.contact-form input, form.contact-form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--mid-color-mid);
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-size: 1em;
  color: var(--main-darker);
}
form.contact-form textarea {
  resize: none;
  line-height: 1.3;
  height: 124px;
}
form.contact-form input:focus, form.contact-form textarea:focus {
  outline: none;
  border: 1px solid var(--main-color);
  box-shadow: 0 0 2px var(--light-color);
}
form.contact-form button {
  border: 0;
  background-color: var(--main-color);
  padding: 10px;
  font-size: 1em;
  color: var(--lighter-color);
  margin: 10px 0 0;
  font-family: 'Lexend Deca', sans-serif;
}
form.contact-form button:hover {
  background-color: var(--main-darker);
  border-radius: 10px 0;
}
#contact ul {
  text-align: left;
  width: 100%;
  margin: 5px 15px;
}
#contact ul li {
  display: inline-block;
}
#contact ul li a {
  font-family: 'Lexend Deca', sans-serif;
  background-color: var(--main-color);
  color: var(--lighter-color);
  padding: 8px;
  margin: 0 5px 5px 0;
  display: block;
}
#contact ul li a:hover {
  background-color: var(--main-darker);
  color: var(--mid-color-lighter);
  border-radius: 0 10px;
}
#contact ul li i { font-size: 1.2em; }
#contact .error {
  padding: 10px 0;
  font-style: italic;
}
#contact .error::first-letter { text-transform: uppercase; }
#contact .send-confirm {
  padding: 10px 0;
  font-size: 1.2em;
  color: var(--main-color);
  font-family: 'Lexend Deca', sans-serif;
  text-transform: uppercase;
}


/* RESUME */
.resume {
  display: flex;
  flex-wrap: wrap;
}
.resume .sidebar {
  align-self: flex-start;
  position: sticky;
  top: 0;
  padding: 10px 50px 0 0;
  margin: -10px 0 0;
}
.resume .sidebar li {
  padding: 0 5px 15px;
}
.resume .sidebar button {
  text-transform: capitalize;
  background: none;
  border: none;
  font-size: 1em;
  padding: 0 0 3px;
  font-weight: bold;
  color: var(--main-darker);
  border-bottom: 5px solid var(--main-color);
}
.resume .sidebar button:hover { border-bottom-color: var(--main-darker); }
.resume .content {
  flex-grow: 1;
  text-align: center;
}
.resume .content p.download {
  text-align: left;
  margin: 0 0 20px 0;
}
.resume .content p.download a {
  background-color: var(--main-color);
  color: var(--lighter-color);
  padding: 10px;
  margin: 5px 0 0;
  display: inline-block;
}
.resume .content p.download a:hover {
  background-color: var(--main-darker);
}
.resume .content h4 {
  font-family: 'Lexend Deca', sans-serif;
  text-transform: capitalize;
  font-size: 2em;
  padding: 10px 0;
  margin-top: -5px;
  color: var(--main-darker);
  text-align: left;
}
.resume .content h4:not(:first-child) { margin-top: 5px; }
.resume .content .disp {
  background-color: rgba(241,242,235,.2);
  border: 1px solid var(--mid-color-lighter);
  padding: 20px;
  margin: 0 0 8px;
  border-radius: 0 20px;
  text-align: left;
}
.resume .content h5 { 
  background-color: var(--main-color);
  display: inline-block;
  padding: 8px 8px 6px;
  color: var(--lighter-color);
  border-radius: 10px 0;
  font-size: 1em;
  margin: 0 10px 10px 0;
}
.resume .content h6 {
  font-family: 'Fanwood Text', serif;
  font-size: 1.7em;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  color: var(--mid-color-dark);
  display: inline-block;
  line-height: 1;
  padding: 0 0 10px;
}
.resume .content span.certification {
  display: block;
  font-family: 'Fanwood Text', serif;
  font-style: italic;
  line-height: 1;
  font-size: 1.2em;
  margin: 0 0 6px;
}
.resume .content p span {
  color: var(--main-color);
  display: block;
  font-style: italic;
}
.resume .content p.loc {
  opacity: .8;
}
.resume .content p.classes {
  margin-top: 5px;
}
.resume .disp p {
  margin: 0;
}
.resume .content .skills {
  overflow: hidden;
  padding: 10px;
}
.resume .content .skl-title button {
  background: none;
  border: none;
  font-size: 1em;
  margin: 5px;
}
.resume .content .skl-title button::before { display: none; }
.resume .content .skl-title button span {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  display: inline-block;
}
.resume .content .skl-title button i {
  padding: 0 0 0 8px;
}
.resume .content .skl-title button:hover {
  color: var(--main-darker);
}
.resume .content .skl-body {
  overflow: hidden;
  height: 1px;
  transition: all 0.3s ease-in-out; 
}
.resume .content .skl-body li, .resume .content .experience ul li {
  margin: 5px;
}
.resume .content .skl-body li i, .resume .content .experience ul li i {
  color: var(--main-color);
  margin-right: 5px;
  font-size: .9em;
}
.resume .content .leaf {
  width: 50px;
  margin: 15px auto;
}


/* PROJECT THUMBS */
.project-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.project-link {
  width: calc(33.33% - 10px);
  margin: 5px;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--mid-color-lighter);
  border-radius: 0 20px;
  background-color: var(--light-color);
}
.project-thumb {
  display: flex;
}
.project-thumb img, .project-thumb-overlay, .project-thumb-text {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 3 / 2;
}
.project-thumb img {
  filter: grayscale(1);
  transform: scale(1.03);
  mix-blend-mode: multiply;
}
.project-thumb-overlay {
  background: var(--lighter-color);
  opacity: .75;
  position: absolute;
  top: 0; left: 0;
}
.project-thumb-text {
  position: absolute;
  top: 0; left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  outline: 1px solid var(--lighter-color);
  outline-offset: -10px;
  border-radius: 0 20px;
}
.project-thumb h4 {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 1.8em;
  padding: 0 20px 10px;
  text-align: center;
  text-shadow:
    1px 1px var(--lighter-color),
    1px -1px var(--lighter-color),
    -1px -1px var(--lighter-color),
    -1px 1px var(--lighter-color);
  color: var(--mid-color-mid);
  margin: 0;
}
.project-thumb h5 {
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.project-thumb h5 span {
  background: var(--main-color);
  font-family: 'Lexend Deca', sans-serif;
  font-size: 1.1em;
  margin: 2px;
  padding: 3px 5px;
  border-radius: 5px 0;
  color: var(--lighter-color);
}
.project-link:hover {
  transform: scale(1.02);
}
.project-link:hover .project-thumb h4 {
  color: var(--darker-color);
}
.project-link:hover img {
  filter: grayscale(0);
}


/* EMBED */
.insertgame {
  padding-top: 56.25%;
  position: relative;
  margin-top: 15px;
}
.gameframe {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 0;
  height: 100%;
}
.iframe-refresh {
  color: var(--main-color);
  margin-top: 10px;
  display: inline-block;
}
.iframe-refresh:hover {
  cursor: pointer;
  color: var(--mid-color-mid);
}


/* GALLERY */
.gallery {
  text-align: center;
}
.gallery img {
  width: calc(25% - 10px);
  object-fit: cover;
  aspect-ratio: 1 / 1;
  margin: 5px;
  opacity: .9;
  outline: 2px solid var(--mid-color-lighter);
  box-shadow: 0 0 3px var(--light-color);
  outline-offset: -6px;
}
.gallery img:hover {
  transform: scale(1.03);
  opacity: 1;
  cursor: pointer;
}
.fslightbox-container {
  background: rgba(0, 0, 0, 0.8) !important;
}
.fslightbox-container img {
  background-color: var(--lighter-color);
  border: 15px solid var(--lighter-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
.fslightbox-toolbar, .fslightbox-slide-btn {
  background: transparent !important;
}
.fslightbox-toolbar-button:nth-child(1), .fslightbox-slide-number-container {
  display: none !important;
}
.fslightbox-container i, .modal-close-btn i {
  color: var(--lighter-color);
  font-size: 1.8em;
  margin: 5px;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
}
.fslightbox-container .fa-x, .modal-close-btn .fa-x {
  margin: 15px 15px 0 0;
}
.fslightbox-container .fa-x:hover, .modal-close-btn .fa-x:hover {
  transform: rotate(180deg);
  cursor: pointer;
}


/* FOOTER */
footer {
  padding: 20px;
  width: 1220px; max-width: 100%;
  text-align: center;
  font-family: 'Fanwood Text', serif;
  font-style: italic;
  font-size: 1.2em;
}
footer a, footer button {
  color: var(--darker-color);
  text-decoration: none;
  background: none;
  border: none;
  font-family: 'Fanwood Text', serif;
  font-style: italic;
  font-size: 1em;
}
footer a:hover, footer button:hover {
  color: var(--dark-color);
  opacity: 0.7;
}
footer ul li {
  display: inline-block;
  margin: 0;
  color: var(--dark-color);
}
footer ul li i {
  margin-left: 8px;
}
footer .socials {
  font-size: 1.2em;
  margin: 0 0 0 10px;
}
footer .socials i {
  margin: 0 5px;
}
footer .socials a {
  color: var(--dark-color);
}
footer .socials a:hover { 
  opacity: 0.7;
}
.foot-info ul li:not(:last-child)::after {
  content: " \2022 ";
  font-size: 0.8em;
  color: var(--main-color);
}


/* MODAL */
.modal-container {
  float: right;
  width: 100%;
  position: relative;
}
.modal,
.modal-background,
.modal-image {
  transition: all 0.5s ease-in-out;
}
.modal {
  width: 1200px;
  max-width: calc(100% - 80px);
  margin: 0 auto;
  border-radius: 0 25px;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  background-color: var(--lighter-color);
  position: absolute;
  margin: 40px;
  z-index: 12;
  overflow-y: auto;
  max-height: calc(100vh - 80px);
}
.modal-background {
  position: fixed;
  z-index: 10;
  top: 0;
  left: calc(300px + 10%);
  width: calc(90% - 300px);
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
}
.modal-close-btn {
  width: 45px; height: 45px;
  position: absolute;
}
.modal-close-btn i {
  position: fixed;
  top: 0; right: 4px;
  z-index: 12;
}
.modal-buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0;
}
.modal-buttons .button {
  margin-right: 2px;
  padding: 8px;
}
.modal-video video, .subsection video {
  width: 100%;
  height: auto;
  margin-top: 15px;
}
.content-modal {
  height: 100vh;
  overflow: hidden;
}
/* Modal Scrollbar */
.modal::-webkit-scrollbar {
  width: 30px;
}
.modal::-webkit-scrollbar-track {
  background: transparent;
}
.modal::-webkit-scrollbar-thumb {
  background: var(--mid-color-lighter);
  border-radius: 30px;
  border: 13px solid transparent;
  background-clip: padding-box;
}
.modal::-webkit-scrollbar-thumb:hover {
  background: var(--light-color);
  border: 13px solid transparent;
  background-clip: padding-box;
}

.tooltip {
  opacity: 1 !important;
  width: auto;
  font-size: 14px !important;
  font-family: 'Lexend Deca', sans-serif;
  margin-top: 3px !important;
  border-radius: 10px 0 !important;
}


body.stop-transitions * {
  transition: none !important;
}


/* ADDITIONAL STYLING */
/* Selection */
::-moz-selection {
  color: var(--mid-color-lighter);
  background: var(--main-darker);
}
::selection {
  color: var(--mid-color-lighter);
  background: var(--main-darker);
}

.subsection-title {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--light-color);
}
.subsection {
  width: 100%;
  margin: 0;
  padding: 20px 40px;
}
.subsection h4 {
  font-family: 'Lexend Deca', sans-serif;
  text-transform: uppercase;
  font-size: 1.4em;
  letter-spacing: -1px;
  margin-top: 0 0 -5px;
}
.subsection:not(:last-of-type) {
  border-bottom: 1px solid var(--light-color);
}
.subsection section {
  padding: 0 0 10px;
}


/* PAGE TRANSITIONS */
.fade-enter { 
  transition: all 300ms;
  opacity: 0;
}
.fade-enter-active, .fade-exit {
  transition: all 300ms;
  opacity: 1;
}
.fade-exit-active {
  transition: all 300ms;
  opacity: 0;
}
.fade-exit-active {
  position: fixed;
  top: 0;
  width: 1200px; max-width: 100%;
  z-index: -1;
}


/* RESPONSIVE */
/* 1420 */
@media screen and (max-width: 1420px) {
  .project-thumb h4 {
    font-size: 1.6em;
  }
  .project-thumb h5 span {
    font-size: .9em;
  }
}

/* 1264 */
@media screen and (max-width: 1264px) {
  h1  {
    font-size: 4em;
  }
  .gallery img {
    width: calc(33% - 10px);
  }
  .project-link {
    width: calc(50% - 10px);
  }
  .project-thumb h4 {
    font-size: 1.5em;
  }
  .project-thumb h5 span {
    font-size: 1em;
  }
}

/* 1024 */
@media screen and (max-width: 1024px) {
  .quick-info {
    order: 2;
    font-size: 14px;
  }
  .info-box {
    width: 130px;
    height: 130px;
  }
  .quick-skills {
    font-size: 0.9em;
  }
  .self-image img {
    margin-bottom: 5px;
  }
  .project-thumb h5 span {
    font-size: .9em;
  }
}

/* 940 */
@media screen and (max-width: 940px) {
  .gallery img {
    width: calc(50% - 10px);
  }
  .project-link {
    width: calc(100% - 10px);
  }
  .project-thumb h4 {
    font-size: 1.6em;
  }
  .project-thumb h5 span {
    font-size: 1em;
  }
}

/* 860 */
@media screen and (max-width: 860px) {
  body {
    font-size: 18px;
    min-height: auto;
  }
  .container {
    float: none;
    width: 100%;
  }
  header {
    padding: 40px;
    width: 100%;
    height: 26vh;
    min-height: 260px;
    transition: all 0.5s ease-in-out;
    position: relative;
    z-index: auto;
  }
  .title {
    z-index: 0;
  }
  h1  {
    font-size: 3.5em;
  }
  h2 {
    font-size: 1.5em;
  }
  .index-header .title {
    order: 1;
  }
  .quick-info {
    font-size: 16px;
    margin: 20px 0 0;
  }
  .info-box {
    width: 160px;
    height: 160px;
    background-color: var(--mid-color-light);
    color: var(--lighter-color);
  }

  nav:not(.index-header nav) {
    margin: 0;
    padding: 0;
    text-align: left;
    position: fixed;
    top: 0; right: -100vw;
    width: 100%;
    max-width: 340px;
    padding: 10px;
    height: 100vh;
    background: var(--darker-color);
    z-index: 1;
    transition: all 0.5s ease-in-out;
  }
  nav.mobile-toggle:not(.index-header nav) {
    right: 0;
    transition: all 0.5s ease-in-out;
  }
  nav:not(.index-header nav) .nav-title {
    display: block;
  }
  nav:not(.index-header nav) a {
    display: block;
    padding: 12px;
    margin: 0;
    font-size: 1.2em;
    letter-spacing: 2px;
    line-height: 1;
    font-weight: 400;
  }
  nav:not(.index-header nav) a:hover {
    color: var(--light-color);
  }
  
  nav:not(.index-header nav) ul {
    transition: 0.5s;
    display: block;
    margin: 0;
    padding: 0;
  }

  nav:not(.index-header nav) li {
    display: block;
    margin: -5px 0 5px;
    padding: 0 0 8px;
    border-bottom: 1px solid var(--mid-color-dark);
  }
    
  nav:not(.index-header nav) li .sub-menu {
    visibility: visible;
    position: relative;
    width: calc(100% - 15px);
    background-color: transparent;
    color: var(--lighter-color);
    text-align: left;
    left: 15px;
    height: auto;
    opacity: 1;
    transform: translateY(0);
  }
  
  nav:not(.index-header nav) li .sub-menu::after { display: none; }
  nav:not(.index-header nav) .sub-menu a::after {
    content: "";
    position: absolute;
    transform: translateY(50%);
    left: 0;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent var(--main-color);
  }
  nav:not(.index-header nav) .sub-menu li {
    border: none;
    padding: 0;
  }
  
  nav:not(.index-header nav) .sub-menu a {
    color: var(--main-color);
    font-size: .75em;
    letter-spacing: 1px;
    border-bottom: 0px;
    margin: 0;
    padding: 5px 10px;
  }
  nav:not(.index-header nav) .sub-menu a:hover { color: var(--light-color); }
  
  nav:not(.index-header nav) .icon {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
  }
  nav:not(.index-header nav) .icon .fa-bars {
    transform: rotate(0deg);
  }
  nav:not(.index-header nav) .icon .fa-x {
    transform: rotate(180deg);
  }
  
  nav:not(.index-header nav) li a.active {
    color: var(--light-color);
  }

  .index-header nav ul {
    flex-direction: column;
  }

  .fade-exit-active {
    position: relative;
  }
  
  .modal-container {
    float: none;
    position: static;
  }
  .modal {
    top: 0; left: 0;
  }
  .modal-background {
    left: 0;
    width: 100%;
  }
  .content-modal header {
    z-index: -1;
  }
  
  .contact-links {
    text-align: center;
    width: calc(50% - 10px);
    padding: 5px;
  }
  .contact-links:first-of-type { width: calc(100% - 10px); }
  .contact-links ul li:first-of-type {
    padding-top: 15px;
  }

  .project-link {
    width: calc(50% - 10px);
  }
  .gallery img {
    width: calc(25% - 10px);
  }

  footer ul li {
    display: block;
    padding: 0;
    line-height: .9;
  }
  footer i {
    margin-left: 0;
  }
  footer a, footer button {
    font-size: 0.85em;
  }
  footer .socials {
    margin-top: 5px;
  }
  footer .socials a, footer .socials button {
    font-size: 1.1em;
  }
  .foot-info ul li:not(:last-child)::after {
    display: none;
  }
}

/* 740 */
@media screen and (max-width: 740px) {
  .quick-skills li {
    flex: 1 33%;
  }
  .project-thumb h4 {
    font-size: 1.3em;
  }
  .project-thumb h5 span {
    font-size: .9em;
  }
}

/* 600 */
@media screen and (max-width: 600px) {
  .resume .sidebar {
    padding: 10px 0 0;
    margin: 0 0 10px;
    width: 100%;
    background-color: var(--lighter-color);
    border-bottom: 1px solid var(--light-color);
  }
  .resume .sidebar li {
    display: inline-block;
    margin: 0 5px 0 0;
  }
  .quick-info {
    flex-direction: column;
  }
  .info-box {
    border-radius: 0 20px;
    width: 100%;
    height: auto;
    margin: 5px;
  }
  .gallery img {
    width: calc(33% - 10px);
  }

  .project-link {
    width: 100%;
    margin: 5px 0;
    overflow: auto;
    border: 0;
    border-radius: 0;
    background-color: transparent;
  }
  .project-thumb {
    flex-wrap: wrap;
  }
  .project-thumb img {
    aspect-ratio: 1 / 1;
    width: 25%;
    min-width: 100px;
    filter: grayscale(0);
    transform: scale(1);
    mix-blend-mode: normal;
    outline: 1px solid var(--lighter-color);
    outline-offset: -5px;
    margin: 5px 15px 5px 5px;
    border: 1px solid var(--mid-color-lighter);
    box-shadow: 0 0 3px var(--mid-color-lighter);
  }
  .project-thumb-overlay {
    display: none;
  }
  .project-thumb-text {
    position: relative;
    aspect-ratio: auto;
    align-items: flex-start;
    outline: 0;
    outline-offset: 0;
    flex: 1;
  }
  .project-thumb h4 {
    font-size: 1.4em;
    padding: 0 0 5px;
    text-align: left;
    text-transform: none;
    color: var(--main-color);
  }
  .project-thumb h5 {
    padding: 0 0;
    justify-content: flex-start;
  }
  .project-thumb h5 span {
    font-size: 1.05em;
    background: transparent;
    color: var(--mid-color-light);
    font-family: 'Lato', sans-serif;
    font-style: italic;
    border-radius: 0;
    padding: 0;
    margin: 2px 4px 2px 0;
  }
  .project-thumb h5 span:not(:last-of-type)::after {
    content: '\00B7';
    margin-left: 4px;
  }
  .project-link:hover {
    transform: scale(1);
  }
  .project-link:hover .project-thumb h4 {
    color: var(--mid-color-mid);
  }
}
/* 400 */
@media screen and (max-width: 400px) {
  .quick-skills {
    flex-direction: column;
  }
  .contact-links {
      width: calc(100% - 10px);
  }
  h1  {
    font-size: 3em;
  }
  h2 {
    font-size: 1.2em;
  }
  .self-image {
    max-width: 100vw;
    overflow: hidden;
  }
  .self-image img {
    width: 80%;
    float: none;
    margin: 5px 50% -5px;
    transform: translateX(-50%);
  }
  .gallery img {
    width: calc(50% - 10px);
  }
  .project-thumb {
    flex-direction: column;
  }
  .project-link:not(:last-of-type) {
    padding: 0 0 12px;
    margin: 0 0 12px;
    border-bottom: 1px solid var(--light-color);
  }
  .project-thumb img {
    min-width: calc(100% - 10px);
    margin: 5px 5px 8px;
    aspect-ratio: 8 / 5;
  }
  .project-thumb-text {
    align-items: center;
  }
  .project-thumb h4 {
    text-align: center;
  }
}